import {FC} from 'react';

import styled from 'styled-components';

interface Props {
  speed?: number;
  height?: number | string;
  width?: number | string;
}

const BaseLoader: FC<Props> = ({speed = 0.75, height = 50, width = 50, ...other}) => (
  <Wrapper $speed={speed} $height={height} $width={width} {...other} data-qa-auto="spinner" />
);

const Wrapper = styled.div<{$speed: number; $height: number | string; $width: number | string}>`
  background: transparent;
  width: ${({$width}) => (typeof $width === 'number' ? `${$width}px` : $width)};
  height: ${({$height}) => (typeof $height === 'number' ? `${$height}px` : $height)};
  border-radius: 50%;
  border: 2px solid rgb(255, 120, 2);
  border-bottom-color: transparent !important;
  display: inline-block;
  animation: spin ${({$speed}) => $speed}s infinite linear;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default BaseLoader;
