import {createRef, useCallback, useEffect, useMemo, useRef, useState} from 'react';

import TextField from '@mui/material/TextField';
import {WidgetInstance} from 'friendly-challenge';
import {NextPage} from 'next';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';

import ErrorMessageText from '@/src/components/AuthModal/Form/ErrorMessageText';
import PasswordField from '@/src/components/AuthModal/Form/PasswordField';
import SubmitButton from '@/src/components/AuthModal/Form/SubmitButton';
import useConfig from '@/src/hooks/useConfig';
import {useSession} from '@/src/hooks/useSession';
import useUserData from '@/src/hooks/useUserData';
import {AuthFormValue} from '@/src/interfaces/types/auth.types';
import {isEmailValid} from '@/src/lib/RegistrationUtils';
import {useGetCountryByIpQuery} from '@/src/lib/store/stores/api';

interface Props {
  emailUpdatedHandler: (value: string) => void;
  emailParam: string;
  agreeTermsValue?: boolean;
}

const Form: NextPage<Props> = ({emailUpdatedHandler, emailParam, agreeTermsValue}) => {
  const config = useConfig();
  const session = useSession();

  const initialFormValue = {
    username: emailParam || '',
    email: emailParam || '',
    password: '',
    twoFactorAuthCode: '',
    recaptchaValue: '',
    agreeTerms: true,
    registrationPage: '',
    isTranslator: false,
    userType: 'customer',
    userCountry: '',
    captchaService: '',
  };

  const [formValue, setFormValue] = useState<AuthFormValue>(initialFormValue);
  const userCountryByIp = useGetCountryByIpQuery()?.data?.userCountryByIp || '';
  const isChina = useMemo(() => ['CN', 'HK'].includes(userCountryByIp), [userCountryByIp]);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const widgetRef = useRef<WidgetInstance | null>(null);

  const {handleLogIn, needTwoFactorAuth, errorMessages, setErrorMessages, isFetchingAuth} = useUserData();

  const {username, email, password, twoFactorAuthCode, recaptchaValue, captchaService} = formValue;

  const recaptchaRef = createRef<ReCAPTCHA>();

  useEffect(() => {
    setFormValue((prevValue) => ({
      ...prevValue,
      agreeTerms: !!agreeTermsValue,
    }));
  }, [agreeTermsValue]);

  const doneCallback = useCallback(
    (solution: string) => {
      if (solution) {
        setFormValue((prevValue) => ({
          ...prevValue,
          recaptchaValue: solution,
          captchaService: 'friendly',
        }));

        if (typeof window !== undefined) {
          window.resetFriendlyCaptcha = () => {
            if (isChina) {
              if (widgetRef.current !== undefined) widgetRef?.current?.reset();
            }
          };
        }
      }
    },
    [isChina]
  );

  const errorCallback = useCallback((err: any) => {
    if (err) {
      setFormValue((prevValue) => ({
        ...prevValue,
        recaptchaValue: '0',
      }));
    }
  }, []);

  const {snData: {emailExists = false, email: socialEmail = null} = {}} = session || {};

  const updateSocialEmail = useCallback(() => {
    if (emailExists && socialEmail) {
      setFormValue((prevValue) => ({
        ...prevValue,
        email: socialEmail,
        username: socialEmail,
      }));
    }
  }, [emailExists, socialEmail]);

  useEffect(() => {
    updateSocialEmail();
  }, [updateSocialEmail]);

  useEffect(() => {
    if (!widgetRef.current && containerRef.current) {
      widgetRef.current = new WidgetInstance(containerRef.current, {
        doneCallback,
        errorCallback,
      });
    }

    return () => {
      if (widgetRef.current !== undefined) widgetRef?.current?.reset();
    };
  }, [containerRef, doneCallback, errorCallback]);

  useEffect(() => {
    if (userCountryByIp && formValue?.userCountry !== userCountryByIp) {
      setFormValue((prevValue) => ({
        ...prevValue,
        userCountry: userCountryByIp,
      }));
    }
  }, [userCountryByIp, formValue?.userCountry]);

  const handleChangeValue = useCallback(
    (value: string | boolean, name: string) => {
      if (name === 'email') {
        setErrorMessages(
          !isEmailValid(value as string) ? (prevState) => ({...prevState, user_email: ['Incorrect email']}) : {}
        );
      }

      setFormValue((prevValue) => ({
        ...prevValue,
        [name]: value,
      }));
    },
    [emailUpdatedHandler, setErrorMessages]
  );

  const handleSubmit = () => {
    handleLogIn(
      {
        username,
        password,
        twoFactorAuthCode,
        recaptchaValue,
        captchaService,
      },
      newConfig
    );
  };

  const handlePreSubmit = useCallback(() => {
    if (isChina) {
      handleSubmit();
    } else {
      recaptchaRef?.current?.reset();
      recaptchaRef?.current?.execute();
    }
  }, [recaptchaRef, isChina]);

  const handleChangeRecaptcha = useCallback((value: string | null) => {
    setFormValue((prevValue) => ({
      ...prevValue,
      recaptchaValue: value,
    }));
  }, []);

  useEffect(() => {
    recaptchaValue && !isChina && handleSubmit();
  }, [recaptchaValue, isChina]);

  const clearErrorMessages = useCallback(() => setErrorMessages({}), [setErrorMessages]);

  const handleResetForm = useCallback(() => {
    setFormValue(initialFormValue);
    updateSocialEmail();
  }, [updateSocialEmail]);

  const newConfig = {...(config || {}), ...(session || {})};

  useEffect(() => {
    clearErrorMessages();
    handleResetForm();
  }, []);

  const submitButtonText = 'Sign In';
  const modalName = 'login-modal';

  const formAttr = {'data-qa-auto': modalName};

  //two-factor auth form
  if (needTwoFactorAuth) {
    return (
      <>
        <Input
          name="twoFactorAuthCode"
          value={twoFactorAuthCode}
          onChange={({target: {value}}) => handleChangeValue(value, 'twoFactorAuthCode')}
          label="Token from Google Authenticator"
          disabled={isFetchingAuth}
          fullWidth
        />
        <SubmitButton
          text={submitButtonText}
          handleSubmit={handleSubmit}
          disabled={isFetchingAuth}
          displayLoader={isFetchingAuth}
        />
      </>
    );
  }

  return (
    <FormItem {...formAttr}>
      <Input
        name="email"
        value={email}
        onChange={({target: {value}}) => {
          handleChangeValue(value, 'email');
          setFormValue((prevValue) => ({
            ...prevValue,
            username: value,
          }));
        }}
        label="Email"
        fullWidth
        error={
          !!errorMessages?.user_email?.length || !!errorMessages?.user?.length || !!errorMessages?.full_name?.length
        }
        helperText={errorMessages?.user_email?.[0] || errorMessages?.user?.[0] || errorMessages?.full_name?.[0]}
        disabled={isFetchingAuth}
        inputProps={{'data-qa-auto': 'email'}}
        variant="outlined"
      />
      <PasswordField
        password={password}
        handleChangeValue={handleChangeValue}
        errorMessages={errorMessages}
        disabled={isFetchingAuth}
        placeholder={'Password'}
      />
      <CaptchaBlock>
        {isChina ? (
          <div style={{margin: '0 0 10px'}}>
            <div ref={containerRef} className="frc-captcha" style={{width: '100%'}} data-sitekey="FCMLPLD9VK0ROBLC" />
          </div>
        ) : (
          <>
            {config?.captchaApiKey && (
              <ReCAPTCHA
                theme="dark"
                size="invisible"
                ref={recaptchaRef}
                sitekey={config?.captchaApiKey || ''}
                onChange={handleChangeRecaptcha}
              />
            )}
          </>
        )}
      </CaptchaBlock>
      <ErrorMessageText data-qa-auto="errmsg" message={errorMessages?.common} />
      <SubmitButton
        text={submitButtonText}
        handleSubmit={handlePreSubmit}
        disabled={isFetchingAuth}
        displayLoader={isFetchingAuth}
      />
    </FormItem>
  );
};

const CaptchaBlock = styled.div`
  margin-bottom: 15px;
`;

const Input = styled(TextField)`
  &.MuiFormControl-root {
    margin-bottom: 0.9rem;

    input {
      div > input {
        padding: 14.4px;
      }
      & p {
        margin-left: 0;
      }
    }
  }
`;

const FormItem = styled.form`
  margin-top: 10px;
`;

export default Form;
